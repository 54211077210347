import { getId } from "~helper/index";

export const _faqs = [
    {
        id: getId(),
        summary: "How much does a custom song cost?",
        details: "The Instant Song costs $19.99 USD and is delivered immediately after purchase. For a fully custom song, the price is $199 USD, and it will be delivered within 4 days of your order. Additionally, we offer a variety of enhancements to personalize your song even further.",
    },
    {
        id: getId(),
        summary: "Is it possible to receive a refund if I am not satisfied with the song?",
        details: "Due to the custom nature of our songs, we are unable to offer refunds. Each song is uniquely tailored to your preferences and requirements, making it impossible to reuse or resell. This level of personalization ensures your song is a one-of-a-kind creation meant just for you",
    },
    {
        id: getId(),
        summary: "How will I receive my song?",
        details: "Once the production process is complete, we will send you an email notification that includes a link to your song page, where you can listen to it and share it with your loved ones.",
    },
    {
        id: getId(),
        summary: "How long will it take for me to receive the song from the moment of ordering?",
        details: "The Instant Song is delivered immediately after purchase. For custom songs, you'll be notified of the delivery date when you place your order, typically within 4 days. The song will be delivered by the end of the chosen date, at 23:59. You can log in to your account anytime to track the production progress.",
    },
    {
        id: getId(),
        summary: "Can you share with me the custom song production process?",
        details: "With our Instant Song option, you can preview and regenerate lyrics until you're completely satisfied, then create your song instantly. For a more personalized touch, our artists craft unique, custom songs based on your input. Whether it's instantly generated or artist-created, the result is always a special musical experience just for you. Sit back and enjoy your song, fresh from Songoven!",
    },
]